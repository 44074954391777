<template>
  <div class="index">
    <keep-alive>
      <router-view class="page"></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  components: {
  },
  created () {
    this.$store.dispatch('loadSysList')
    this.$store.dispatch('loadAppServiceList')
    this.$store.dispatch('loadSysVersionList')
    this.$store.dispatch('loadServerList')
    this.$store.dispatch('loadAppList')
    this.$store.dispatch('loadAppVersionList')
  },
}
</script>

<style scoped lang="less">
.index {
  display: flex;
  flex-direction: column;
}
.page {
  height: calc(100% - 3rem);
  width: 100%;
}
</style>